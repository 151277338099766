
import Home from './Home';

function App() {
  return (
    <div className="App">
      <Home 
      style={{
        minHeight: '100%'
      }}
      />
    </div>
  );
}

export default App;
