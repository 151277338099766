import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { githubIcon, linkedinIcon } from '../Icons';
import '/Users/fernando/portfolio2024/src/global.css'



const NavbarComponent = () => {
  return (
  
    <Navbar   
    className="bg-body-tertiary fundo">
      <Container className='fundo flex-row nav'
      style={{
        position: 'fixed',
        top: '0',
        width: '100vw'
      }}
      >
        <div className='icons-container'>
          <Navbar.Brand href="#home">
            <a href="https://github.com/fxdevuk"
            target='_blank'
            rel='noreferrer'
            className='nav-icon' >{githubIcon()}</a>
            </Navbar.Brand>
          <Navbar.Brand href="#home">
            <a href="https://www.linkedin.com/in/fernando-xavier-788151243/"
            target='_blank'
            rel='noreferrer'
            className='nav-icon' >{linkedinIcon()}</a>
          </Navbar.Brand>
        </div>
        <div className='fonte-escura contact-container'>
          <a className="contact" href="#contact">contact</a>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;