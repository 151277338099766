import FooterPic from "./components/FooterPic"
import FormPortfolio from "./components/FormPortfolio"
import IconsSection from "./components/IconsSection"
import NavbarComponent from "./components/Navbar"
import Projects from "./components/Projects"
import TextoApresentacao from "./components/TextoApresentacao"
import './global.css'




const Home = () => {
  return (
  <div className="body">
  <NavbarComponent />
  <TextoApresentacao />
  <IconsSection />
  <Projects />
  <FormPortfolio />
  <FooterPic />
  </div>
  )
}

export default Home