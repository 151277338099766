


const FormPortfolio = () => {
  return (
  <div className="form-container" id='contact'>
    <form className='form' action="https://formsubmit.co/e6aaa7afe4d88094f355a994f4d9cb34" method="POST">
                        
                        <input className='input' type="text"  id="fullname" name="fullname" placeholder="full name" required />
                
                        <input className='input' type="text" id="company" name="company" placeholder="company" required />
                        
                        <input className='input' type="tel"  id="telephone" name="telephone" placeholder="telephone" required />
                        
                        <input className='input' type="email" id="email" name="email" placeholder="email" required />

                        <textarea className='input'  id="subject" name="subject" placeholder="message" required></textarea>
                    
                        <button className="input-button submit-btn-hover" type="submit" value="Submit">submit</button>
                    </form>
                    </div>
  )
}

export default FormPortfolio