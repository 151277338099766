import { bootstrapIcon, cssIcon, figmaIcon, htmlIcon, jsIcon, reactIcon } from "./Icons"
import '/Users/fernando/portfolio2024/src/global.css'



const IconsSection = () => {
  return (
    <div className="icons-section fundo">
      <ul className="icons-list">
        <li className="icons-list-item" >{reactIcon()}</li>
        <li className="icons-list-item" >{jsIcon()}</li>
        <li className="icons-list-item" >{cssIcon()}</li>
        <li className="icons-list-item" >{figmaIcon()}</li>
        <li className="icons-list-item" >{htmlIcon()}</li>
        <li className="icons-list-item" >{bootstrapIcon()}</li>
      </ul>
    </div>
  )
}

export default IconsSection