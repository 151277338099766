import '/Users/fernando/portfolio2024/src/global.css'


const Projects = () => {
  return (
    <div className="bg-dark">
    <p className='fonte-clara fonte projects'>projects</p>
  <div className='projects-container'>
      <img 
      className='img-projects'
      src='./images/devices_website_guauu.png' alt='Four devices showing the landing pages of Guauu website'></img>
      <p className='fonte-clara fonte texto-claro'>"¡Guauu!" is a Spanish startup for which I was responsible for website design, co-responsible for frontend development, and UX. It's a high-end restaurant reservation scheduling company.</p>
    </div>
</div>
  )
}

export default Projects