import Image from 'react-bootstrap/Image';
import '/Users/fernando/portfolio2024/src/global.css'
import { Container } from 'react-bootstrap';



const FooterPic = () => {
  return (
    <div className='fundo footer fixed-bottom'
    style={{
      marginTop: 'auto',
      
    }}
    >
    <Image 
    className='footer-pic'
    src="./images/img_portfolio.png" fluid />
    </div>
  )
}

export default FooterPic