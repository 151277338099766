import '/Users/fernando/portfolio2024/src/global.css'


const TextoApresentacao = () => {
  return (
    <div className='fundo textos apresentacao'>
    <p className='texto-escuro'>Hi, I'm Fernando, a London-based Junior Frontend Developer. Design is my passion, and I love transforming ideas into fantastic web experiences. I'm committed to focusing on the nitty-gritty details and continuously improving my skills. Let's collaborate and create some seriously cool stuff together!</p>
    </div>
  )
}

export default TextoApresentacao